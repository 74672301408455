import consumer_trains_order_getTrainsOrderList
    from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderList' // 获取火车票订单
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel' // 取消订单
import consumer_trains_order_getTrainsOrderStatus from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderStatus'
import Pagination from '@/component/pagination/index.vue'

export default {
    data() {
        return {
            loading: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                orderStatus: 0, // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 出行类型
                passengerName: '', // 乘客姓名
                total: 0
            },
            orderStatusList: [ // 0:全部 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
                {value: 0, label: '全部'},
                {value: 10, label: '超标审批中'},
                {value: 1, label: '待确认'},
                {value: 2, label: '待支付'},
                {value: 3, label: '待出票'},
                {value: 4, label: '出票成功'},
                {value: 5, label: '出票失败'},
                {value: 6, label: '已取消'}
            ],
            scheduledWayList: [
                {value: '', label: '全部'},
                {value: 2, label: '因公出差'},
                {value: 1, label: '因私出行'}
            ],
            orderList: [],
            reloadTicketFun: '',
            reloadNum: 0,
            showLoading: true,
            trainsOrderStatus: [
                {
                  text: "全部",
                  value: 0,
                }
            ],
        }
    },
    components: {
        Pagination,
    },
    activated() {
        this.getTrainsOrderList()
        this.reloadList()
        consumer_trains_order_getTrainsOrderStatus().then((res) => {
            this.trainsOrderStatus = res.datas.result
            this.trainsOrderStatus.unshift({
              text: "全部",
              value: 0,
            });
        });
    },
    deactivated() {
        clearInterval(this.reloadTicketFun)
    },
    destroyed() {
        clearInterval(this.reloadTicketFun)
    },
    filters: {
        filterWhatDay(val) {
            if (val === 1) {
                return '周一'
            } else if (val === 2) {
                return '周二'
            } else if (val === 3) {
                return '周三'
            } else if (val === 4) {
                return '周四'
            } else if (val === 5) {
                return '周五'
            } else if (val === 6) {
                return '周六'
            } else if (val === 7) {
                return '周日'
            } else {
                return ''
            }
        }
    },
    methods: {
        //搜索
        search() {
            this.params.currentPage = 1;
            this.getTrainsOrderList()
        },

        //重置筛选条件
        onReset() {
            this.params = {
                currentPage: 1,
                pageSize: 10,
                orderStatus: 0, // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 出行类型
                passengerName: '', // 乘客姓名
                total: 0
            }
            this.getTrainsOrderList();
        },

        getTrainsOrderList() {
            if (this.showLoading) {
                this.loading = true
            }
            consumer_trains_order_getTrainsOrderList(this.params).then(res => {
                this.loading = false
                this.showLoading = true
                this.orderList = res.datas.list;
                this.params.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.showLoading = true
                this.orderList = []
                this.params.total = 0
            })
        },

        goDetail(val) {
            this.$router.push({
                name: 'admin-my-order-train-detail',
                query: {
                    orderNo: val.orderNo
                }
            })
        },

        goPay(val) {
            if (val.scheduledWay === 1) {
                this.$router.push({
                    name: 'admin-personal-train-submit-order',
                    query: {
                        orderNo: val.orderNo
                    }
                })
            } else {
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderType: '2',
                        orderNos: val.orderNo
                    }
                })
            }

        },

        cancelOrder(val) {
            this.$confirm('一天仅有3次取消订单的机会(包括自动取消)，超过3次后当日将无法使用12306账号预订', '确认要取消订单吗', {
                type: 'warning',
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
                callback: action => {
                    if (action == 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        consumer_trains_order_applyForCancel({orderNo: val.orderNo}).then(res => {
                            loading.close();
                            this.$alert('你已成功取消预订', '取消预订提示', {
                                confirmButtonText: '知道了',
                                callback: action => {
                                    this.getTrainsOrderList();
                                    this.$message({
                                        message: '订单取消成功',
                                        type: 'success'
                                    });
                                }
                            })
                        }).catch(() => {
                            loading.close();
                            this.$message.error('订单取消失败');
                        })
                    }
                }
            });
        },

        reloadList() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++
                if (this.reloadNum === 60) {
                    this.showLoading = false
                    this.getTrainsOrderList()
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },

        //订单状态的样式
        // 订单状态 0:全部 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
        returnStatus(item) {
            if (item.orderStatus === 4) {
                return 'status2' // 绿色
            } else if (item.orderStatus === 5) {
                return 'status3' // 红色
            } else if (item.orderStatus === 6) {
                return 'status4' // 灰色
            } else {
                return 'status1' // 黄色
            }
        },
    }
}
